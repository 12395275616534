import './Home.css';
import { Helmet } from 'react-helmet';

import Carousel from './carousel/Carousel.js';
import BenefitsCarousel from './benefits/Benefits.js';
import usePageTracking from '../../../hooks/usePageTracking.js';
import ModalNovoHospital from './ModalHospital/ModalNovoHospital.js';

const Home = () => {

    usePageTracking();

    return (
        <div className='home'>

            <Helmet>
                <link rel="canonical" href="https://www.gocarelitoral.com.br" />
                <title>GoCare Saúde Litoral | Planos de Saúde na Baixada Santista</title>
                <meta name="description"
                    content="Conheça o melhor e mais barato plano de saúde da Baixada Santista. Contrate online GoCare Saúde: serviços médicos de qualidade e atendimento humanizado com carência reduzida para o Litoral. Garanta agora mais saúde e bem-estar com facilidade e conveniência." />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "GoCare Saúde Litoral",
                        "url": "https://gocarelitoral.com.br",
                        "logo": "https://gocarelitoral.com.br/static/media/logo.ae940211769127fa78a0.png",
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "telephone": "+55 13 98126-0507",
                            "contactType": "sales",
                            "areaServed": "BR",
                            "availableLanguage": ["Portuguese"]
                        },
                        "sameAs": [
                            "https://www.facebook.com/gocare.litoral",
                            "https://www.instagram.com/gocare.litoral"
                        ]
                    })}
                </script>
            </Helmet>

            <ModalNovoHospital />
            <Carousel />
            <BenefitsCarousel />

        </div>
    )
}

export default Home;