import './Planos.css'
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import usePageTracking from '../../../hooks/usePageTracking.js';
import CardPlanos from './CardPlanos/CardPlanos.js';
import CopModal from './CopModal/CopModal.js';
// import ModalAvisoReajuste from './ReajusteModal/ReajusteModal.js';

const Planos = () => {

    const structuredDataPlans = {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": "Plano de Saúde GoCare",
        "url": "https://gocarelitoral.com.br/planos",
        "description": "Explore os diversos planos de saúde oferecidos pela GoCare Saúde no Litoral. Planos individuais, familiares e empresariais com cobertura nacional em urgências e emergências, telemedicina 24h, e uma ampla rede credenciada.",
        "brand": {
            "@type": "Brand",
            "name": "GoCare Saúde Litoral"
        },
        "image": [
            "https://gocarelitoral.com.br/static/media/banner_adesao_mobile.4847e427ba870a72754a.png",
            "https://gocarelitoral.com.br/static/media/banner_adesao.f949ce296d44f5b93d7e.png",
            "https://gocarelitoral.com.br/static/media/banner_empresarial_mobile.5ff5acf7a953cd835f0f.png",
            "https://gocarelitoral.com.br/static/media/banner_empresarial.e074b1ad0e7bfe7e2532.png",
            "https://gocarelitoral.com.br/static/media/banner_indfam_mobile.1867390e0ed5207e2f86.png",
            "https://gocarelitoral.com.br/static/media/banner_indfam.097df98176ee12036a4d.png",
            "https://gocarelitoral.com.br/static/media/banner_adesaoestudantil_mobile.ef2073a40d57a45754ca.png",
            "https://gocarelitoral.com.br/static/media/banner_adesaoestudantil.531806afc229fa85261d.png"
        ],
        "offers": [
            {
                "@type": "Offer",
                "name": "GoCare Litoral A",
                "priceCurrency": "BRL",
                "price": "145.61",
                "description": "Preço mínimo válido para a faixa etária de 00 a 18 anos.",
                "eligibleRegion": {
                    "@type": "Place",
                    "name": "Baixada Santista, SP"
                },
                "url": "https://gocarelitoral.com.br/planos",
                "availability": "http://schema.org/InStock"
            },
            {
                "@type": "Offer",
                "name": "GoCare Litoral Especial A",
                "priceCurrency": "BRL",
                "price": "187.77",
                "description": "Preço mínimo válido para a faixa etária de 00 a 18 anos.",
                "eligibleRegion": {
                    "@type": "Place",
                    "name": "Baixada Santista, SP"
                },
                "url": "https://gocarelitoral.com.br/planos",
                "availability": "http://schema.org/InStock"
            },
            {
                "@type": "Offer",
                "name": "GoCare Litoral E",
                "priceCurrency": "BRL",
                "price": "129.37",
                "description": "Preço mínimo válido para a faixa etária de 00 a 18 anos.",
                "eligibleRegion": {
                    "@type": "Place",
                    "name": "Baixada Santista, SP"
                },
                "url": "https://gocarelitoral.com.br/planos",
                "availability": "http://schema.org/InStock"
            },
            {
                "@type": "Offer",
                "name": "GoCare Litoral Especial E",
                "priceCurrency": "BRL",
                "price": "148.78",
                "description": "Preço mínimo válido para a faixa etária de 00 a 18 anos.",
                "eligibleRegion": {
                    "@type": "Place",
                    "name": "Baixada Santista, SP"
                },
                "url": "https://gocarelitoral.com.br/planos",
                "availability": "http://schema.org/InStock"
            },
            {
                "@type": "Offer",
                "name": "GoCare Litoral I",
                "priceCurrency": "BRL",
                "price": "207.00",
                "description": "Preço mínimo válido para a faixa etária de 00 a 18 anos.",
                "eligibleRegion": {
                    "@type": "Place",
                    "name": "Baixada Santista, SP"
                },
                "url": "https://gocarelitoral.com.br/planos",
                "availability": "http://schema.org/InStock"
            },
            {
                "@type": "Offer",
                "name": "GoCare Litoral Especial I",
                "priceCurrency": "BRL",
                "price": "238.05",
                "description": "Preço mínimo válido para a faixa etária de 00 a 18 anos.",
                "eligibleRegion": {
                    "@type": "Place",
                    "name": "Baixada Santista, SP"
                },
                "url": "https://gocarelitoral.com.br/planos",
                "availability": "http://schema.org/InStock"
            },
            {
                "@type": "Offer",
                "name": "GoCare Litoral A Adesão Estudantil",
                "priceCurrency": "BRL",
                "price": "141.74",
                "description": "Preço mínimo válido para a faixa etária de 00 a 18 anos.",
                "eligibleRegion": {
                    "@type": "Place",
                    "name": "Baixada Santista, SP"
                },
                "url": "https://gocarelitoral.com.br/planos",
                "availability": "http://schema.org/InStock"
            },
            {
                "@type": "Offer",
                "name": "GoCare Litoral Especial A Adesão Estudantil",
                "priceCurrency": "BRL",
                "price": "182.78",
                "description": "Preço mínimo válido para a faixa etária de 00 a 18 anos.",
                "eligibleRegion": {
                    "@type": "Place",
                    "name": "Baixada Santista, SP"
                },
                "url": "https://gocarelitoral.com.br/planos",
                "availability": "http://schema.org/InStock"
            }
        ]
    };





    const [showModal, setShowModal] = useState(false);

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    usePageTracking();

    return (
        <section className="page__plans-section">

            <Helmet>
                <link rel="canonical" href="https://www.gocarelitoral.com.br/planos" />
                <title>Planos de Saúde | GoCare Saúde Litoral</title>
                <meta name="description"
                    content="Explore os diversos planos de saúde oferecidos pela GoCare Saúde no Litoral. Planos individuais, familiares e empresariais com cobertura completa e atendimento humanizado na Baixada Santista." />

                <script
                    type="application/ld+json">
                    {JSON.stringify(structuredDataPlans)}
                </script>

            </Helmet>

            <div className="page__plans-container">
                <div className="page__plans-text">
                    <div className="page__plans-title">
                        <h2><strong>Valores e Coberturas</strong> de <strong>Planos de Saúde<br /></strong> para o <strong>Litoral</strong></h2>
                    </div>
                    <div className="div-separate"></div>
                </div>

                <CardPlanos />

                {/* <ModalAvisoReajuste /> */}

                <div className='modal__container'>
                    <p>
                        Clique no botão a seguir para visualizar a tabela de coparticipação com os valores máximos de terapias, procedimentos e materiais importados.
                    </p>
                    <button onClick={openModal}>
                        Ver Tabela de Coparticipação
                    </button>
                </div>

                <CopModal showModal={showModal} closeModal={closeModal} />

            </div >

        </section >

    )
}

export default Planos;